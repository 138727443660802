<template>
    <div class="home-layout">

        <div class="home-row">
            <router-link class="home-col" :to="{ name: 'MarketRedirect' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/statistic.svg">
                        <h2 class="home-widget-title">بازار داخلی</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" to="/market/global-markets">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/stock-market.svg">
                        <h2 class="home-widget-title">بازار جهانی</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" to="/retail/retail-market">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/retailMarket/shopping-cart.png?asdisd">
                        <h2 class="home-widget-title">بازار خرده فروشی</h2>
                    </div>
                </div>  
            </router-link>
<!-- /home/soheil115/dashboard/apps/client/public/img/food/آرایشی و بهداشتی.png -->
            <router-link class="home-col" to="/market/global-markets/list/29906">
                <div class="home-widget" @click="setCurrentMarketTab(4)">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/stock-price.svg">
                        <h2 class="home-widget-title">بازار کریپتو</h2>
                    </div>
                </div>  
            </router-link>

            <!-- <div class="intro-home-ads" v-if="this.$store.state.adsStatic && adshome" v-html="adshome"></div> -->

            <router-link class="home-col" to="/news">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/stock(1).svg">
                        <h2 class="home-widget-title">اخبـار</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'Forums' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/chat.svg">
                        <h2 class="home-widget-title">گفت و گوها</h2>
                    </div>
                </div>  
            </router-link>



            <div class="tgju-widget-slider" style="height: 95px;margin: 4px 5px 5px;">
                <div class="tgju-widget-slider-control">
                    <div class="summary-widget-tag">
                        <div>
                            <div class="control-next"><i class="fa fa-chevron-down" aria-hidden="true"></i></div>
                            <div class="control-prev"><i class="fa fa-chevron-up" aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
                <ul>
                    <li v-for="(item, index) in market_values" :key="index">
                        <div class="summary-widget-box">
                            <div class="summary-widget-content block1item">
                                <div :class="'summary-widget row-'+ item.dt +' tr-'+item.dt" :data-market-coding="item.code" :data-market-row="item.item_id">
                                    <div class="summary-widget-right">
                                        <div class="summary-widget-price" data-market-name="p">
                                            {{ formatPrice(item.p) }}
                                        </div>
                                        <h2>
                                            <div class="summary-widget-title">{{ item.title }}</div>
                                        </h2>
                                    </div> 
                                    <div class="summary-widget-left">
                                        <div class="summary-widget-change-title">
                                            تغییر روزانه
                                        </div>
                                        <div class="summary-widget-change" data-market-name="d">
                                            (% {{ item.dp }}) {{ item.d }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>


            <div class="tgcss-pt-3">
                <newsCategory :cat="'اخبار-ویژه'" :count="20" :title="'دسته‌بندی‌اخبار'" :type="'slug'" />
            </div>


            <router-link class="home-col" :to="{ name: 'CurrencyExchange', params: { type: 'sell' },}">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/size.png?1">
                        <h2 class="home-widget-title">صرافـی‌های<br>داخلی‌ارزی</h2>
                    </div>
                </div>  
            </router-link>
            <router-link class="home-col" :to="{ name: 'LocalExchanges', params: { type: 'sell' },}">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/bitcoin-wallet.png">
                        <h2 class="home-widget-title">صرافـــی‌هـای<br>داخلی‌کریپتو</h2>
                    </div>
                </div>  
            </router-link>
            <router-link class="home-col" :to="{ name: 'GlobalExchanges'}">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/exchange.png">
                        <h2 class="home-widget-title">صرافـــی‌هـای<br>جهانی‌کریپتو</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'NewTechnical' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/goal.svg">
                        <h2 class="home-widget-title">تحلیل‌ها</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'EconomicsAtlas', params: { country: 'in' },}">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/stock.svg">
                        <h2 class="home-widget-title">کشورها</h2>
                    </div>
                </div>  
            </router-link>
   
            <router-link class="home-col" :to="{ name: 'EconomicsCountryListCategory'}">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/money-exchange.svg">
                        <h2 class="home-widget-title">شاخص‌ها</h2>
                    </div>
                </div>  
            </router-link>

            <!-- 
                <div class="home-row">
                    <div class="home-col-w">
                        <div class="home-widget">
                            <div class="home-widget-in">
                                <img class="home-widget-img" src="https://www.tgju.org/files/icons/others/accounting-3.svg">
                                <h2 class="home-widget-title">تست ویجت</h2>
                            </div>
                        </div> 
                    </div>  
                </div> 
            -->

            <!-- <router-link class="home-col" :to="{ name: 'AddTechnical' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/account.svg">
                        <h2 class="home-widget-title">افزودن تحلیل</h2>
                    </div>
                </div>  
            </router-link> -->

            <!-- <router-link class="home-col" :to="{ name: 'FavoritesView' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/link.svg">
                        <h2 class="home-widget-title">بوک مارک‌ها</h2>
                    </div>
                </div>  
            </router-link> -->

            <router-link class="home-col" :to="{ name: 'AlertsView' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/bell.svg">
                        <h2 class="home-widget-title">هشدارها</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'WatchListsView' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/analysis.svg">
                        <h2 class="home-widget-title">واچ لیست‌ها</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'Investment' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/market.svg">
                        <h2 class="home-widget-title">سبد دارایی</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'ToolsIntro' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/analysis.svg">
                        <h2 class="home-widget-title">ابزار‌ها</h2>
                    </div>
                </div>  
            </router-link>
            
            <router-link class="home-col" :to="{ name: 'TodoView' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/clipboard.svg">
                        <h2 class="home-widget-title">وظایف روزانه</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'NotesView' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/documents.svg">
                        <h2 class="home-widget-title">یادداشت ها</h2>
                    </div>
                </div>  
            </router-link>


            <router-link class="home-col" :to="{ name: 'Profile' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/profile.svg">
                        <h2 class="home-widget-title">نمایه کاربر</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'ProfileEdit' }" >
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/profile(1).svg">
                        <h2 class="home-widget-title">حساب کاربری</h2>
                    </div>
                </div>  
            </router-link>

            <router-link class="home-col" :to="{ name: 'Dashboards' }">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" src="https://static.tgju.org/views/default/images/tgju/app/news/blue1/1/performance.svg">
                        <h2 class="home-widget-title">داشبوردها</h2>
                    </div>
                </div>  
            </router-link>
            
            <news2RowSlide :id="100005" class="tgcss-mt-3"  :cat="'اخبار-ویژه'" :count="30" :title="'اخبار ویژه'" :type="'tag'" />


        </div>
    </div>

</template>

<script>
import newsCategory from '@/components/Inc/newsCategory.vue'
import news2RowSlide from '@/components/Inc/news2RowSlide.vue'

    export default {
        name: 'HomeIntro',
        components: {
            newsCategory,
            news2RowSlide
        },
        data: function () {
            return {
                market_values : [],
                // adshome: null
            }
        },
        mounted() {
            this.getMarketValue('137204,391293,137121,137120,137119,398096,663201,137137,137138,137139,137140,137141,131398');
            var self = this;
            jQuery(document).ready(function ($) {
                $('.tgju-widget-slider').each(function() {
                    var that = this;
                    setInterval(function () {
                        self.sliderMoveUp(that);
                    }, 6000);
                
                    var slideCount = $(this).find('ul li').length;
                    var slideWidth = $(this).find('ul li').width();
                    var slideHeight = $(this).find('ul li').height();
                    var sliderUlHeight = slideCount * slideHeight;
                    
                    $(this).css({ height: slideHeight });
                    
                    $(this).find('ul').css({ height: sliderUlHeight, marginTop: - slideHeight });
                    
                    $(this).find('ul li:last-child').prependTo($(this).find('ul'));

                    $(this).find('.control-prev').click(function () {
                        self.sliderMoveUp($(this).parents().eq(2)[0].parentNode);
                    });

                    $(this).find('.control-next').click(function () {
                        self.sliderMoveDown($(this).parents().eq(2)[0].parentNode);
                    });

                });
            })
            // var globalAds = window.setInterval(() => {
            //     if(this.$store.state.adsData){ 
            //         if(this.$store.state.adsData[18]){
            //             this.adshome = '<ul><li data-place-name="'+ this.$store.state.adsData[18].name +'" class="show">'
            //                     + '<a target="_blank" href="'+ this.$store.state.adsData[18].url +'">'
            //                     + '<img src="'+ this.$store.state.adsData[18].image +'" alt="">'
            //                     + '</a>'
            //                     + '</li></ul>';
            //             this.$store.commit('setAdsStatic', true);
            //         }else{
            //             this.$store.commit('setAdsStatic', false);
            //         }                              
            //         clearInterval(globalAds);
            //     }
            // }, 100);
            
        },
        computed: {},
        methods: {
 
            getMarketValue(ids) {
                this.$helpers
                .makeRequest("GET", "/market/list?market_ids="+ids)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_values = api_response.data.response.detail;
                    } 
                });
            },
            sliderMoveDown(slider) {
                $(slider).find('ul').animate({
                    top: + $(slider).find('ul li').height()
                }, 500, function () {
                    $(slider).find('ul li:last-child').prependTo($(slider).find('ul'));
                    $(slider).find('ul').css('top', '');
                });
            },

            sliderMoveUp(slider) {
                $(slider).find('ul').animate({
                    top: - $(slider).find('ul li').height()
                }, 500, function () {
                    $(slider).find('ul li:first-child').appendTo($(slider).find('ul'));
                    $(slider).find('ul').css('top', '');
                });
            },
            setCurrentMarketTab(tab,) {
                this.$store.commit('setCurrentTabMarket', tab);
            },
             // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
            formatPrice(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        },
    }
</script>